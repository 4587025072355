// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-modal-index-js": () => import("./../src/pages/modal/index.js" /* webpackChunkName: "component---src-pages-modal-index-js" */),
  "component---src-pages-success-2-index-js": () => import("./../src/pages/success2/index.js" /* webpackChunkName: "component---src-pages-success-2-index-js" */),
  "component---src-pages-success-3-index-js": () => import("./../src/pages/success3/index.js" /* webpackChunkName: "component---src-pages-success-3-index-js" */),
  "component---src-pages-success-index-js": () => import("./../src/pages/success/index.js" /* webpackChunkName: "component---src-pages-success-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-convenios-js": () => import("./../src/templates/convenios.js" /* webpackChunkName: "component---src-templates-convenios-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-service-page-js": () => import("./../src/templates/servicePage.js" /* webpackChunkName: "component---src-templates-service-page-js" */)
}

